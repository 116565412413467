import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  sidebar: {
    requests: 'Requests',
  },
  breadcrumbs: {
    requests: 'Requests',
    requestDetails: 'Request details',
  },
  pendingRequestsPage: {
    pendingRequest: 'Pending requests',
    results: 'Showing {resultsNumber} results',
    title: 'Title',
    createdOn: 'Created on',
    vendor: 'Vendor',
    country: 'Country',
    tool: 'Tool',
    id: 'ID',
    requester: 'Requester',
    pagination: { pageSizeOptions: 'Items per page: {options}' },
    emptyMessage: "There's no information to show",
    commom: {
      filter: 'Filter',
      search: 'Search by title, ID or requester',
    },
  },
  resolvedRequestsPage: {
    resolvedRequest: 'Resolved requests',
    results: 'Showing {resultsNumber} results',
    title: 'Title',
    status: 'Status',
    createdOn: 'Created on',
    lastEdited: 'Last edited',
    vendor: 'Vendor',
    country: 'Country',
    tool: 'Tool',
    id: 'ID',
    requester: 'Requester',
    approver: 'Approver',
    pagination: { pageSizeOptions: 'Items per page: {options}' },
    commom: {
      filter: 'Filter',
      search: 'Search by title, ID or requester',
    },
    emptyMessage: "There's no information to show",
  },
  statusRequests: {
    approved: 'Approved',
    failed: 'Failed',
    processing: 'Processing',
    rejected: 'Rejected',
    pending: 'Pending',
    canceled: 'Canceled',
    pendingInfo: 'This request is pending review.',
    failedInfo: "The request was approved, but because of a system error, it wasn't completed.",
    processingInfo: 'The approval of this request is being processed.',
    rejectedInfo: 'This request has been rejected.',
    approvedInfo: 'This request has been approved.',
    canceledInfo: 'This request has been canceled.',
  },
  tabs: {
    pending: 'Pending',
    resolved: 'Resolved',
  },
  detailsContainer: {
    requestDetails: 'Request details',
    vendor: 'Vendor',
    country: 'Country',
    requesters: 'Requester',
    sharedWith: 'Shared with',
    showMore: 'Show more',
    tool: 'Tool',
    createdOn: 'Created on',
    approver: 'Approver',
    lastEdited: 'Last edited',
    id: 'ID',
    requestLink: 'Open URL request',
  },
  approvalRequest: {
    error: 'Error',
    errorMessage: 'Something went wrong. Please try again.',
    rejectedSuccessfully: 'Request successfully rejected.',
    approveSuccessfully: 'Your approval was submitted and is being processed.',
    modal: {
      approveButton: 'Approve',
      rejectButton: 'Reject',
      cancelButton: 'No, go back',
      approveAccept: 'Yes, approve',
      approveDescription: 'Approve request?',
      approveTitle: 'Approve request?',
      rejectTitle: 'Reject request?',
      acceptWarn: 'Once you approve it, you can’t undo this action.',
      rejectAccept: 'Yes, reject',
      rejectReason: 'Add a rejection reason. You won’t be able to undo this action.',
      reasonTextArea: 'Reason:',
      rejectionError: 'You must enter the rejection reason.',
    },
    table: {
      information: 'Information',
      currentValue: 'Current value',
      newValue: 'New value',
      deletedValue: 'Deleted value',
      noValue: 'No value',
      removed: 'Removed value',
      removedTooltip: 'This information was removed by the requester.',
      noValueTooltip: 'This field was empty before this request.',
      noValueProvidedTooltip: 'No information provided.',
    },
    informationContainer: {
      requestType: 'Request Type',
      infoToReview: 'Information to review',
      reviewedInfo: 'Reviewed information',
      rejectReason: 'Rejection reason',
      processingAlert: 'The approval of this request is being processed.',
      failedAlert: "The request was approved, but because of a system error, it wasn't completed.",
      canceledAlert:
        'The request was canceled. The actions or alterations requested were not implemented.',
      requestTypeName: {
        update: 'Update',
        insert: 'Create',
        delete: 'Delete',
      },
    },
  },
  filter: {
    status: 'Status',
    vendor: 'Vendor',
    vendors: 'Vendors',
    tool: 'Tool',
    tools: 'Tools',
    createdOn: 'Created on',
    lastEdited: 'Last edited',
    startDate: 'Start date',
    endDate: 'End date',
    endDateHint: 'Leave this field empty to search only by the start date.',
    endDateError: 'The end date must be after the start date.',
    invalidDateError: 'Invalid date.',
    clearFilters: 'Clear filters',
    applyFilters: 'Apply',
    cancel: 'Cancel',
    search: 'Search',
  },
  table: {
    loading: 'Loading items...',
  },
  pagination: {
    of: 'of',
  },
};

export default enUS;
