import { Tabs } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

export interface RequestTabsProps {
  defaultPage: 'Pending' | 'Resolved';
}

const RequestTabs = ({ defaultPage }: RequestTabsProps): JSX.Element => {
  const intl = useIntl();

  return (
    <Container>
      <Tabs.Root defaultValue={defaultPage}>
        <Tabs.List>
          <StyledLink to="/approval-flow">
            <Tabs.Trigger value="Pending">
              {intl.formatMessage({ id: 'tabs.pending' })}
            </Tabs.Trigger>
          </StyledLink>
          <StyledLink to="/approval-flow/resolved-requests">
            <Tabs.Trigger value="Resolved">
              {intl.formatMessage({ id: 'tabs.resolved' })}
            </Tabs.Trigger>
          </StyledLink>
        </Tabs.List>
      </Tabs.Root>
    </Container>
  );
};

const StyledLink = styled(Link, {
  textDecoration: 'none',
});

const Container = styled('div', {
  width: '100%',
  marginBottom: '$6',
});

export default RequestTabs;
