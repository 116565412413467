import MessageMap from '../i18n';

const deDE: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  sidebar: {
    requests: 'Anfragen',
  },
  breadcrumbs: {
    requests: 'Anfragen',
    requestDetails: 'Anfragedetails',
  },
  pendingRequestsPage: {
    pendingRequest: 'Ausstehende Anfragen',
    results: 'Es werden {resultsNumber} Ergebnisse angezeigt',
    title: 'Titel',
    createdOn: 'Erstellt am',
    vendor: 'Anbieter',
    country: 'Land',
    tool: 'Tool',
    id: 'ID',
    requester: 'Anfragender',
    pagination: { pageSizeOptions: 'Zeilen pro seite: {options}' },
    emptyMessage: 'Sobald es ausstehende Anfragen gibt, werden sie hier aufgeführt.',
    commom: {
      filter: 'Filter',
      search: 'Nach Titel, ID oder Anfragenden suchen',
    },
  },
  resolvedRequestsPage: {
    resolvedRequest: 'Bearbeitete Anfragen',
    results: 'Es werden {resultsNumber} Ergebnisse angezeigt',
    title: 'Titel',
    status: 'Status',
    createdOn: 'Erstellt am',
    lastEdited: 'Zuletzt bearbeitet',
    vendor: 'Anbieter',
    country: 'Land',
    tool: 'Tool',
    id: 'ID',
    requester: 'Anfragender',
    approver: 'Genehmiger',
    pagination: { pageSizeOptions: 'Zeilen pro seite: {options}' },
    commom: {
      filter: 'Filter',
      search: 'Nach Titel, ID oder Anfragenden suchen',
    },
    emptyMessage: 'Sobald die Anfragen bearbeitet wurden, werden sie hier aufgeführt.',
  },
  statusRequests: {
    approved: 'Genehmigt',
    failed: 'Fehlgeschlagen',
    processing: 'Wird verarbeitet',
    rejected: 'Abgelehnt',
    pending: 'Ausstehend',
    canceled: 'Abgesagt',
    pendingInfo: 'Diese Anfrage wurde noch nicht geprüft.',
    failedInfo:
      'Die Anfrage wurde genehmigt, aber aufgrund eines Systemfehlers wurde sie nicht abgeschlossen.',
    processingInfo: 'Die Genehmigung dieser Anfrage wird verarbeitet.',
    rejectedInfo: 'Diese Anfrage wurde abgelehnt.',
    approvedInfo: 'Diese Anfrage wurde genehmigt.',
    canceledInfo: 'Diese Anfrage wurde abgesagt.',
  },
  tabs: {
    pending: 'Ausstehend',
    resolved: 'Bearbeitet',
  },
  detailsContainer: {
    requestDetails: 'Anfragedetails',
    vendor: 'Anbieter',
    country: 'Land',
    requesters: 'Anfragender',
    tool: 'Tool',
    createdOn: 'Erstellt am',
    approver: 'Genehmiger',
    lastEdited: 'Zuletzt bearbeitet',
    id: 'ID',
    requestLink: 'URL-Anfrage öffnen',
  },
  approvalRequest: {
    error: 'Fehler',
    errorMessage: 'Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut.',
    rejectedSuccessfully: 'Anfrage erfolgreich abgelehnt. ',
    approveSuccessfully: 'Deine Genehmigung wurde eingereicht und wird verarbeitet.',
    modal: {
      approveButton: 'Genehmigen',
      rejectButton: 'Ablehnen',
      cancelButton: 'Nein, zurück',
      approveAccept: 'Ja, genehmigen',
      approveDescription: 'Anfrage genehmigen?',
      approveTitle: 'Anfrage genehmigen?',
      rejectTitle: 'Anfrage ablehnen?',
      acceptWarn: 'Nach der Genehmigung kannst du diese Aktion nicht rückgängig machen. ',
      rejectAccept: 'Ja, ablehnen',
      rejectReason:
        'Füge einen Ablehnungsgrund hinzu. Du kannst diese Aktion nicht rückgängig machen. ',
      reasonTextArea: 'Grund:',
      rejectionError: 'Du musst den Ablehnungsgrund eingeben.',
    },
    table: {
      information: 'Informationen',
      currentValue: 'Aktueller Wert',
      newValue: 'Neuer Wert',
      deletedValue: 'Gelöschter Wert',
      noValue: 'Kein Wert',
      removed: 'Entfernter Wert',
      removedTooltip: 'Diese Informationen wurden vom Anfragenden entfernt.',
      noValueTooltip: 'Dieses Feld war vor dieser Anfrage leer.',
      noValueProvidedTooltip: 'Diese Informationen wurden vom Anfragenden entfernt.',
    },
    informationContainer: {
      requestType: 'Art der Anfrage',
      infoToReview: 'Zu überprüfende Informationen',
      reviewedInfo: 'Informationen überprüft',
      rejectReason: 'Ablehnungsgrund',
      processingAlert: 'Die Genehmigung dieser Anfrage wird verarbeitet. ',
      failedAlert:
        'Die Anfrage wurde genehmigt, aber aufgrund eines Systemfehlers wurde sie nicht abgeschlossen.',
      requestTypeName: {
        update: 'Aktualisieren',
        insert: 'Erstellen',
        delete: 'Löschen',
      },
    },
  },
  filter: {
    status: 'Status',
    vendor: 'Anbieter',
    vendors: 'Anbieter',
    tool: 'Tool',
    tools: 'Tools',
    createdOn: 'Erstellt am',
    lastEdited: 'Zuletzt bearbeitet',
    startDate: 'Startdatum',
    endDate: 'Enddatum',
    endDateHint: 'Lasse dieses Feld leer, um nur nach dem Startdatum zu suchen.',
    endDateError: 'Das Enddatum muss nach dem Startdatum liegen.',
    invalidDateError: 'Ungültiges Datum.',
    clearFilters: 'Filter löschen',
    applyFilters: 'Anwenden',
    cancel: 'Abbrechen',
    search: 'Suchen',
  },
  table: {
    loading: 'Artikel werden geladen...',
  },
  pagination: {
    of: 'von',
  },
};

export default deDE;
