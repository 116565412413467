import { styled } from '@hexa-ui/theme';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { isAfter, isSameDay } from 'date-fns';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { formatToDateTime } from '@/utils/date';
import { DateInput } from './DateInput';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  padding: '1rem 3.5rem 0 1.5rem',
});

export interface DateFilterProps {
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
}

function dateIsValid(dateString: string, lng: string) {
  const [month, day, year] = dateString.split('/');

  const isoFormattedString = {
    'pt-BR': `${year}-${day}-${month}`,
    'es-419': `${year}-${day}-${month}`,
    'en-US': `${year}-${month}-${day}`,
  };

  const date = new Date(isoFormattedString[lng] || isoFormattedString['en-US']);
  const timestamp = date.getTime();

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(isoFormattedString[lng] || isoFormattedString['en-US']);
}

function isAfterMinDate(minDate: string, dateToCompare: string, preferredLanguage?: string) {
  const formattedDateToCompare = formatToDateTime(dateToCompare, preferredLanguage);
  const formattedMinDate = formatToDateTime(minDate, preferredLanguage);
  return (
    isAfter(formattedDateToCompare, formattedMinDate) ||
    isSameDay(formattedDateToCompare, formattedMinDate)
  );
}

const COMPLETE_DATE_LENGTH = 10;

export function DateFilter({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: DateFilterProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { preferredLanguage } = usePreferredLanguageV2();

  const [startDateInput, setStartDateInput] = useState(startDate);
  const [startDateInputError, setStartDateInputError] = useState('');
  const [endDateInput, setEndDateInput] = useState(endDate);
  const [endDateInputError, setEndDateInputError] = useState('');

  const validateStartDate = (date: string) => {
    if (!date.length) {
      setStartDateInputError('');
      setStartDate(date);
    }

    if (date.length === COMPLETE_DATE_LENGTH) {
      if (!dateIsValid(date, preferredLanguage)) {
        setStartDate('');
        return setStartDateInputError(formatMessage({ id: 'filter.invalidDateError' }));
      }

      setStartDateInputError('');
      setStartDate(date);
    }
  };

  const validateEndDate = (date: string) => {
    if (!date.length) {
      setEndDateInputError('');
      setEndDate(date);
    }

    if (date.length === COMPLETE_DATE_LENGTH) {
      if (!dateIsValid(date, preferredLanguage)) {
        setEndDate('');
        return setEndDateInputError(formatMessage({ id: 'filter.invalidDateError' }));
      }

      if (!startDateInput) {
        setEndDate('');
        return setEndDateInputError(formatMessage({ id: 'filter.endDateError' }));
      }

      if (!isAfterMinDate(startDateInput, date, preferredLanguage)) {
        setEndDate('');
        return setEndDateInputError(formatMessage({ id: 'filter.endDateError' }));
      }

      setEndDateInputError('');
      setEndDate(date);
    }
  };

  useEffect(() => {
    validateStartDate(startDateInput);
    validateEndDate(endDateInput);
  }, [startDateInput, endDateInput]);

  return (
    <Container>
      <div data-testid="start-date-input">
        <DateInput
          id="start-date"
          maxLength={10}
          label={formatMessage({ id: 'filter.startDate' })}
          onChange={setStartDateInput}
          value={startDateInput}
          preferredLanguage={preferredLanguage}
          error={startDateInputError}
        />
      </div>
      <div data-testid="end-date-input">
        <DateInput
          maxLength={10}
          id="end-date"
          label={formatMessage({ id: 'filter.endDate' })}
          onChange={setEndDateInput}
          hintText={formatMessage({ id: 'filter.endDateHint' })}
          value={endDateInput}
          error={endDateInputError}
          preferredLanguage={preferredLanguage}
        />
      </div>
    </Container>
  );
}

export default DateFilter;
