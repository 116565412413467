import { Grid } from '@hexa-ui/components';
import { Checklist1, List } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import { useSidebar } from 'admin-portal-shared-services';
import { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

export const sidebarIcons = {
  requestsReceived: () => <Checklist1 />,
  requestsSent: () => <List />,
};

const AppBarConfigProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  const { formatMessage } = useIntl();

  useSidebar({
    items: [
      {
        id: '3',
        title: formatMessage({ id: 'sidebar.requests' }),
        icon: sidebarIcons.requestsReceived,
        path: '/approval-flow',
      },
    ],
    utils: [],
  });

  return (
    <StyledGrid type="fluid" sidebar>
      {children}
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid.Container, {
  paddingTop: '$4',
  rowGap: '1rem',
  '@desktopSmall': {
    paddingTop: 0,
  },
});

export default AppBarConfigProvider;
