import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Loading } from './components';
import FilterContextProvider from './components/FilterBar/Filter/FilterContext';

const ApprovalRequest = lazy(() => import('./pages/ApprovalRequest/ApprovalRequest'));
const PendingRequestsPage = lazy(() => import('./pages/PendingRequestsPage/PendingRequestsPage'));
const ResolvedRequestsPage = lazy(() => import('./pages/ResolvedRequestsPage/ResolvedRequestsPage'));

export const BASE_PATH = '/approval-flow';

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loading />}>
              <PendingRequestsPage />
            </Suspense>
          }
        />
        <Route
          path={BASE_PATH}
          element={
            <Suspense fallback={<Loading />}>
              <FilterContextProvider>
                <PendingRequestsPage />
              </FilterContextProvider>
            </Suspense>
          }
        />
        <Route
          path={`${BASE_PATH}/resolved-requests`}
          element={
            <Suspense fallback={<Loading />}>
              <FilterContextProvider>
                <ResolvedRequestsPage />
              </FilterContextProvider>
            </Suspense>
          }
        />
        <Route
          path={`${BASE_PATH}/approval-request/:requestId`}
          element={
            <Suspense fallback={<Loading />}>
              <ApprovalRequest />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
