import { styled } from '@hexa-ui/theme';

export const ChipsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$2',
  flexWrap: 'wrap',
  padding: '0 $6',
  marginBottom: '$4',
});
