import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
});

export const StyledStatus = styled('div', {
  width: '0.5rem',
  height: '0.5rem',
  background: 'green',
  borderRadius: '50%',
  variants: {
    variant: {
      approved: { background: '$semanticSuccessText' },
      rejected: { background: '$semanticErrorText' },
      failed: { background: '$semanticErrorText' },
      canceled: { background: '$semanticErrorText' },
      processing: { background: '$semanticInfoBasis' },
      pending: { background: '$brandAccentWarmBasis' },
    },
  },
});

export const StatusText = styled('span', {
  fontFamily: '$paragraph',
  fontSize: '0.875rem',
});
