import { Tooltip } from '@hexa-ui/components';
import { useIntl } from 'react-intl';

import { Container, StatusText, StyledStatus } from './Status.styles';

interface ValidateStatusProps {
  type: string;
  hasTooltip?: boolean;
  fontSizeStatus?: string;
}

const ValidateStatus = ({
  hasTooltip = true,
  fontSizeStatus = '1rem',
  type,
}: ValidateStatusProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const statusBase = {
    COMPLETED: {
      variant: 'approved',
      statusName: formatMessage({ id: 'statusRequests.approved' }),
      tooltipMessage: formatMessage({ id: 'statusRequests.approvedInfo' }),
    },
    REJECTED: {
      variant: 'rejected',
      statusName: formatMessage({ id: 'statusRequests.rejected' }),
      tooltipMessage: formatMessage({ id: 'statusRequests.rejectedInfo' }),
    },
    PROCESSING: {
      variant: 'processing',
      statusName: formatMessage({ id: 'statusRequests.processing' }),
      tooltipMessage: formatMessage({ id: 'statusRequests.processingInfo' }),
    },
    PENDING: {
      variant: 'pending',
      statusName: formatMessage({ id: 'statusRequests.pending' }),
      tooltipMessage: formatMessage({ id: 'statusRequests.pendingInfo' }),
    },
    FAILED: {
      variant: 'failed',
      statusName: formatMessage({ id: 'statusRequests.failed' }),
      tooltipMessage: formatMessage({ id: 'statusRequests.failedInfo' }),
    },
    CANCELED: {
      variant: 'canceled',
      statusName: formatMessage({ id: 'statusRequests.canceled' }),
      tooltipMessage: formatMessage({ id: 'statusRequests.canceledInfo' }),
    },
  };

  return (
    <Container>
      {hasTooltip ? (
        <Tooltip text={statusBase[type]?.tooltipMessage} placement="top">
          <StyledStatus variant={statusBase[type]?.variant} />
        </Tooltip>
      ) : (
        <StyledStatus variant={statusBase[type]?.variant} />
      )}
      <StatusText css={{ fontSize: fontSizeStatus }}>{statusBase[type]?.statusName}</StatusText>
    </Container>
  );
};
export { ValidateStatus };
