import { Button } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  display: 'flex',
  padding: '1rem 1.5rem',
  justifyContent: 'space-between',
});

export const Content = styled('div', {
  display: 'flex',
});

export const ButtonFilter = styled(Button, {
  display: 'flex',
  gap: '0.3rem',
  '& > div': {
    background: 'transparent',
    marginRight: 0,
  },
});

export const SearchFieldContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  height: '8.125rem',
});

export const ChipsContainer = styled('div', {
  display: 'flex',
  padding: '1rem 0.5rem 1rem 0rem',
});
