import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Língua portuguesa selecionada',
  },
  sidebar: {
    requests: 'Solicitações',
  },
  breadcrumbs: {
    requests: 'Solicitações',
    requestDetails: 'Detalhes da solicitação',
  },
  pendingRequestsPage: {
    pendingRequest: 'Solicitações pendentes',
    results: 'Mostrando {resultsNumber} resultados',
    title: 'Título',
    createdOn: 'Criada em',
    vendor: 'Fornecedor',
    country: 'País',
    tool: 'Ferramenta',
    id: 'ID',
    requester: 'Solicitante',
    pagination: { pageSizeOptions: 'Linhas por página: {options}' },
    emptyMessage: 'Quando houver solicitações pendentes, elas serão listadas aqui.',
    commom: {
      filter: 'Filtro',
      search: 'Pesquisar por título, ID ou solicitante',
    },
  },
  resolvedRequestsPage: {
    resolvedRequest: 'Solicitações resolvidas',
    results: 'Mostrando {resultsNumber} resultados',
    title: 'Título',
    status: 'Status',
    createdOn: 'Criada em',
    lastEdited: 'Última edição',
    vendor: 'Fornecedor',
    country: 'País',
    tool: 'Ferramenta',
    id: 'ID',
    requester: 'Solicitante',
    approver: 'Aprovador',
    pagination: { pageSizeOptions: 'Linhas por página: {options}' },
    commom: {
      filter: 'Filtro',
      search: 'Pesquisar por título, ID ou solicitante',
    },
    emptyMessage: 'Assim que as solicitações forem resolvidas, elas serão listadas aqui.',
  },
  statusRequests: {
    approved: 'Aprovado',
    failed: 'Falha',
    processing: 'Processando',
    rejected: 'Rejeitado',
    pending: 'Pendente',
    canceled: 'Cancelada',
    pendingInfo: 'Esta solicitação está em análise.',
    failedInfo:
      'A solicitação foi aprovada, mas devido a um erro do sistema, ela não foi concluída.',
    processingInfo: 'A aprovação da solicitação está sendo processada.',
    rejectedInfo: 'Esta solicitação foi recusada.',
    approvedInfo: 'Esta solicitação foi aprovada.',
    canceledInfo: 'Esta solicitação foi cancelada.',
  },
  tabs: {
    pending: 'Pendente',
    resolved: 'Resolvido',
  },
  detailsContainer: {
    requestDetails: 'Detalhes da solicitação',
    vendor: 'Fornecedor',
    country: 'País',
    requesters: 'Solicitante',
    sharedWith: 'Compartilhado com',
    showMore: 'Mostrar mais',
    tool: 'Ferramenta',
    createdOn: 'Criada em',
    approver: 'Aprovador',
    lastEdited: 'Última edição',
    id: 'ID',
    requestLink: 'Abrir URL da solicitação',
  },
  approvalRequest: {
    error: 'Erro',
    errorMessage: 'Algo deu errado. Por favor, tente novamente.',
    rejectedSuccessfully: 'Solicitação rejeitada com sucesso.',
    approveSuccessfully: 'Sua aprovação foi enviada e está sendo processada.',
    modal: {
      approveButton: 'Aprovar',
      rejectButton: 'Rejeitar',
      cancelButton: 'Não, voltar',
      approveAccept: 'Sim, aprovar',
      approveDescription: 'Aprovar solicitação?',
      approveTitle: 'Aprovar solicitação?',
      rejectTitle: 'Rejeitar solicitação?',
      acceptWarn: 'Depois de aprová-la, você não poderá desfazer esta ação.',
      rejectAccept: 'Sim, rejeitar',
      rejectReason: 'Adicione um motivo de rejeição. Você não poderá desfazer esta ação.',
      reasonTextArea: 'Motivo:',
      rejectionError: 'Você deve inserir o motivo da rejeição.',
    },
    table: {
      information: 'Informações',
      currentValue: 'Valor atual',
      newValue: 'Novo valor',
      deletedValue: 'Valor excluído',
      noValue: 'Nenhum valor',
      removed: 'Valor removido',
      removedTooltip: 'Essas informações foram removidas pelo solicitante.',
      noValueTooltip: 'Este campo estava vazio antes desta solicitação.',
      noValueProvidedTooltip: 'Essas informações foram removidas pelo solicitante.',
    },
    informationContainer: {
      requestType: 'Tipo de solicitação',
      infoToReview: 'Informações para revisão',
      reviewedInfo: 'Informações revisadas',
      rejectReason: 'Motivo da rejeição',
      processingAlert: 'A aprovação da solicitação está sendo processada.',
      failedAlert:
        'A solicitação foi aprovada, mas devido a um erro do sistema, ela não foi concluída.',
      requestTypeName: {
        update: 'Atualizar',
        insert: 'Criar',
        delete: 'Excluir',
      },
    },
  },
  filter: {
    status: 'Status',
    vendor: 'Fornecedor',
    vendors: 'Fornecedores',
    tool: 'Ferramenta',
    tools: 'Ferramentas',
    createdOn: 'Criada em',
    lastEdited: 'Última edição',
    startDate: 'Data de início',
    endDate: 'Data final',
    endDateHint: 'Deixe este campo vazio para pesquisar apenas pela data de início.',
    endDateError: 'A data final deve ser posterior à data de início.',
    invalidDateError: 'Data inválida.',
    clearFilters: 'Limpar filtros',
    applyFilters: 'Aplicar',
    cancel: 'Cancelar',
    search: 'Procurar',
  },
  table: {
    loading: 'Carregando itens...',
  },
  pagination: {
    of: 'de',
  },
};

export default ptBR;
