import { QueryClientProvider } from 'react-query';

import Router from './Router';
import { EnvConfig, EnvProvider } from './components';
import { queryClient } from './config/reactQuery';
import { useSegmentAnalytics } from './hooks/useSegmentAnalytics';
import { IntlProvider } from './i18n';
import AppBarConfigProvider from './utils/AppBarConfigProvider';

export default function App(props: EnvConfig) {
  useSegmentAnalytics(props.segmentKey);

  return (
    <EnvProvider env={props}>
      <IntlProvider>
        <AppBarConfigProvider>
          <QueryClientProvider client={queryClient}>
            <Router />
          </QueryClientProvider>
        </AppBarConfigProvider>
      </IntlProvider>
    </EnvProvider>
  );
}
