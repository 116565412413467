import { styled } from '@hexa-ui/theme';

export const ActionsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const ContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  height: '26rem',
  overflow: 'hidden',
});

export const TabSelection = styled('ul', {
  display: 'flex',
  width: '17.5rem',
  flexDirection: 'column',
  listStyle: 'none',
  padding: '0',
  margin: '0',
  paddingTop: '$4',
  borderRight: '1px solid',
  borderColor: '$interfaceSurfaceSecondary',
  overflow: 'auto',
});

export const TabContent = styled('div', {
  width: '25.5rem',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
});

export const contentCss = {
  display: 'flex',
  padding: '0px',
  marginBottom: '$6',
  borderBottom: '1px solid',
  borderColor: '$interfaceSurfaceSecondary',
};
