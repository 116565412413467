import MessageMap from '../i18n';

const koKR: MessageMap = {
  test: {
    translate: 'Translate',
    missing: '선택한 한국어',
  },
  sidebar: {
    requests: '요청',
  },
  breadcrumbs: {
    requests: '요청',
    requestDetails: '요청 세부 정보',
  },
  pendingRequestsPage: {
    pendingRequest: '보류 중인 요청',
    results: '{resultsNumber}개의 결과 표시 중',
    title: '제목',
    createdOn: '생성됨',
    vendor: '벤더',
    country: '국가',
    tool: '도구',
    id: 'ID',
    requester: '요청자',
    pagination: { pageSizeOptions: '페이지당 행: {options}' },
    emptyMessage: '보류 중인 요청이 있을 시 여기에 나열됩니다.',
    commom: {
      filter: '필터',
      search: '제목, ID, 요청자로 검색',
    },
  },
  resolvedRequestsPage: {
    resolvedRequest: '해결된 요청',
    results: '{resultsNumber}개의 결과 표시 중',
    title: '제목',
    status: '상태',
    createdOn: '생성됨',
    lastEdited: '최종 편집',
    vendor: '벤더',
    country: '국가',
    tool: '도구',
    id: 'ID',
    requester: '요청자',
    approver: '승인자',
    pagination: { pageSizeOptions: '페이지당 행: {options}' },
    commom: {
      filter: '필터',
      search: '제목, ID, 요청자로 검색',
    },
    emptyMessage: '해결된 요청이 있을 시 여기에 나열됩니다.',
  },
  statusRequests: {
    approved: '승인됨',
    failed: '실패함',
    processing: '처리 중',
    rejected: '거부됨',
    pending: '보류 중',
    canceled: '취소 된',
    pendingInfo: '이 요청은 검토 보류 중입니다.',
    failedInfo: '요청이 승인되었으나, 시스템 오류로 인해 완료되지 못했습니다.',
    processingInfo: '이 요청의 승인이 처리 중입니다.',
    rejectedInfo: '이 요청은 거부됐습니다.',
    approvedInfo: '이 요청은 승인됐습니다.',
    canceledInfo: '이 요청은 승인되었습니다.',
  },
  tabs: {
    pending: '보류 중',
    resolved: '해결됨',
  },
  detailsContainer: {
    requestDetails: '요청 세부 정보',
    vendor: '벤더',
    country: '국가',
    requesters: '요청자',
    sharedWith: '공유 대상',
    showMore: '더 보기',
    tool: '도구',
    createdOn: '생성됨',
    approver: '승인자',
    lastEdited: '최종 편집',
    id: 'ID',
    requestLink: 'URL 요청 열기',
  },
  approvalRequest: {
    error: '오류',
    errorMessage: '문제가 발생했습니다. 다시 시도해주세요.',
    rejectedSuccessfully: '요청이 성공적으로 거부됐습니다.',
    approveSuccessfully: '승인이 제출되었으며 처리 중입니다.',
    modal: {
      approveButton: '승인',
      rejectButton: '거부',
      cancelButton: '아니요, 돌아가겠습니다.',
      approveAccept: '예, 승인합니다.',
      approveDescription: '요청을 승인하시겠습니까?',
      approveTitle: '요청을 승인하시겠습니까?',
      rejectTitle: '요청을 거부하시겠습니까?',
      acceptWarn: '승인하면 이 조치를 되돌릴 수 없습니다.',
      rejectAccept: '예, 거부하겠습니다.',
      rejectReason: '거부 이유 추가하기  이 조치를 되돌릴 수 없습니다.',
      reasonTextArea: '이유:',
      rejectionError: '거부 이유를 입력해야 합니다.',
    },
    table: {
      information: '정보',
      currentValue: '현재 값',
      newValue: '신규 값',
      deletedValue: '삭제된 값',
      noValue: '값 없음',
      removed: '제거된 값',
      removedTooltip: '이 정보는 요청자에 의해 제거됐습니다.',
      noValueTooltip: '이 요청 전에 이 필드는 공란이었습니다.',
      noValueProvidedTooltip: '이 정보는 요청자에 의해 제거됐습니다.',
    },
    informationContainer: {
      requestType: '요청 유형',
      infoToReview: '정보 검토',
      reviewedInfo: '검토한 정보',
      rejectReason: '거부 이유',
      processingAlert: '이 요청의 승인이 처리 중입니다.',
      failedAlert: '요청이 승인되었으나, 시스템 오류로 인해 완료되지 못했습니다.',
      requestTypeName: {
        update: '업데이트',
        insert: '생성',
        delete: '삭제',
      },
    },
  },
  filter: {
    status: '상태',
    vendor: '벤더',
    vendors: '벤더',
    tool: '도구',
    tools: '도구',
    createdOn: '생성됨',
    lastEdited: '최종 편집',
    startDate: '시작일',
    endDate: '종료일',
    endDateHint: '시작일로만 검색하려는 경우 이 필드를 공란으로 두십시오.',
    endDateError: '종료일은 시작일 이후여야 합니다.',
    invalidDateError: '잘못된 날짜.',
    clearFilters: '필터 지우기',
    applyFilters: '적용',
    cancel: '취소',
    search: '찾다',
  },
  table: {
    loading: '항목을 불러오는 중...',
  },
  pagination: {
    of: '의',
  },
};

export default koKR;
