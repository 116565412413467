import { isValid, parse, startOfDay } from 'date-fns';

const getLocalizedDate = (date: number | Date, location?: string): string => {
  return new Intl.DateTimeFormat(location, {
    dateStyle: 'medium',
  }).format(date);
};

const getFullLocalizedDate = (date: number | Date, location?: string): string => {
  return `${new Intl.DateTimeFormat(location, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).format(date)} at ${new Intl.DateTimeFormat(location, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    hourCycle: 'h12',
  }).format(date)}`;
};

const formatToDateTime = (value: string, lng: string): Date | null => {
  const dateFormats = {
    'pt-BR': 'dd/MM/yyyy',
    'es-419': 'dd/MM/yyyy',
    'en-US': 'MM/dd/yyyy',
  };

  const date = parse(value, dateFormats[lng] ?? dateFormats['en-US'], new Date());

  return isValid(date) ? startOfDay(date) : null;
};

function formatPlaceholderDateInput(lng: string) {
  const setPlaceholderDate = {
    'pt-BR': 'dd/mm/aaaa',
    'es-419': 'dd/mm/aaaa',
    'en-US': 'mm/dd/yyyy',
  };
  return setPlaceholderDate[lng] ?? setPlaceholderDate['en-US'];
}

export { formatPlaceholderDateInput, formatToDateTime, getFullLocalizedDate, getLocalizedDate };
