import { formatPlaceholderDateInput } from '@/utils/date';
import { Input } from '@hexa-ui/components';
import React from 'react';
import InputMask from 'react-input-mask';

interface Props {
  maxLength: number;
  hintText?: string;
  label: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  error?: string;
  preferredLanguage?: string;
  id?: string;
  'data-testid'?: string;
}

export function DateInput({
  hintText,
  maxLength,
  label,
  onChange,
  value,
  preferredLanguage = 'en-US',
  'data-testid': dataTestId,
  id,
  error,
}: Props) {
  return (
    <InputMask
      mask="99/99/9999"
      maskChar={null}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      id={id}
      data-testid={dataTestId}
    >
      {(inputProps) => (
        <Input
          maxLength={maxLength}
          label={label}
          type="text"
          hasError={!!error}
          errorText={error}
          hint={hintText}
          width="20.5rem"
          placeholder={`${formatPlaceholderDateInput(preferredLanguage)}`}
          style={{ height: '40px' }}
          {...inputProps}
          data-testid="date-input"
        />
      )}
    </InputMask>
  );
}

export default DateInput;
