import { ListItem as ListItemComponent } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { useCallback } from 'react';

const ListItem = styled(ListItemComponent, {
  variants: {
    active: {
      true: {
        backgroundColor: '$brandAccentNeutralBackground',
      },
      false: {},
    },
  },
});

interface FilterTabProps {
  tab?: {
    id: string;
    name: string;
  };
  index?: number;
  setStateFunction?: (tabIndex: number) => void;
  activeTab?: number;
}

export const FilterTab = ({ tab, index, activeTab, setStateFunction }: FilterTabProps) => {
  const handleTabChange = useCallback(() => {
    setStateFunction(index);
  }, []);

  return (
    <ListItem
      variant="selectable"
      active={index === activeTab}
      spacing="medium"
      data-testid={`filter-tab-${tab.id}`}
      key={`tab-${tab.id}`}
      onClick={handleTabChange}
    >
      {tab.name}
    </ListItem>
  );
};
