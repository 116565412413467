import { Radio as RadioComponent } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

type Option = {
  label: string;
  value: string;
};
interface RadioProps {
  options: Option[];
  value: Option;
  setValue: React.Dispatch<React.SetStateAction<{ value: string; label: string }>>;
}

export const Radio = ({ options, value, setValue }: RadioProps) => {
  const { Item } = RadioComponent;

  const onValueChange = (value) => {
    setValue({ value, label: options.find((option) => option.value === value)?.label });
  };

  return (
    <Root value={value?.value} onValueChange={onValueChange}>
      {options &&
        options.map((option) => (
          <Item key={option.value} id={option.value} label={option.label} value={option.value} />
        ))}
    </Root>
  );
};

const Root = styled(RadioComponent.Root, {
  paddingLeft: '1.5rem',
  paddingTop: '0.75rem',
});
