import { Badge, Paragraph, SearchField } from '@hexa-ui/components';
import { useIntl } from 'react-intl';

import { Filter } from './Filter';
import { Filters } from './Filter/FilterContext';
import { Container, Content } from './FilterBar.styles';
import { SelectedValues } from './SelectedValues';

export interface FilterBarProps {
  defaultPage: 'Pending' | 'Resolved';
  onSearch?: (value: string, event: React.KeyboardEvent<HTMLInputElement>) => void;
  totalResults?: number;
  onClearSearch?: () => void;
  onApplyFilters?: (filters: Filters) => void;
  onClearFilters?: () => void;
  filterValues?: Filters;
}

const FilterBar = ({
  onClearSearch,
  defaultPage,
  onSearch,
  totalResults,
  onApplyFilters,
  onClearFilters,
  filterValues,
}: FilterBarProps): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Container>
        <Content css={{ alignItems: 'center', height: '3rem' }}>
          <Paragraph weight="semibold" css={{ fontSize: '$5', marginRight: '$2' }}>
            {defaultPage === 'Pending'
              ? formatMessage({ id: 'pendingRequestsPage.pendingRequest' })
              : formatMessage({ id: 'resolvedRequestsPage.resolvedRequest' })}
          </Paragraph>
          {defaultPage === 'Pending' && !!totalResults && (
            <Badge.Counter size="large" counterLabel={totalResults} />
          )}
          <Paragraph css={{ marginLeft: '$4', fontSize: '$2', color: '$interfaceLabelSecondary' }}>
            {defaultPage === 'Pending'
              ? formatMessage(
                {
                  id: 'pendingRequestsPage.results',
                },
                { resultsNumber: totalResults }
              )
              : formatMessage(
                {
                  id: 'resolvedRequestsPage.results',
                },
                { resultsNumber: totalResults }
              )}
          </Paragraph>
        </Content>

        <Content css={{ alignItems: 'center', gap: '1rem' }}>
          <Filter
            defaultPage={defaultPage}
            onApplyFilters={onApplyFilters}
            onClearFilters={onClearFilters}
            filterValues={filterValues}
          />
          <SearchField.Root
            data-testid="search-field"
            width="22.75rem"
            height="3rem"
            onClear={onClearSearch}
            placeholder={
              defaultPage === 'Pending'
                ? formatMessage({ id: 'pendingRequestsPage.commom.search' })
                : formatMessage({ id: 'resolvedRequestsPage.commom.search' })
            }
            onSearch={onSearch}
          />
        </Content>
      </Container>
      <SelectedValues
        onApplyFilters={onApplyFilters}
        onClearFilters={onClearFilters}
        filterValues={filterValues}
      />
    </>
  );
};

export default FilterBar;
