import { Button, Chip, Dialog, Divider, SearchField, TextButton } from '@hexa-ui/components';
import { ChevronDown, Filter as FilterIcon, X } from '@hexa-ui/icons';
import { usePreferredLanguageV2, useUserMetadata } from 'admin-portal-shared-services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ButtonFilter, ChipsContainer, SearchFieldContainer } from '../FilterBar.styles';
import { DateFilter } from './DateFilter';
import {
  ActionsContainer,
  ContentContainer,
  TabContent,
  TabSelection,
  contentCss,
} from './Filter.styles';
import { Filters, defaultInitialFilterValues, useFilter } from './FilterContext';
import { FilterTab } from './FilterTab';
import { Radio } from './Radio';

interface FilterProps {
  defaultPage: 'Pending' | 'Resolved';
  onApplyFilters?: (filters: Filters) => void;
  onClearFilters?: () => void;
  filterValues?: Filters;
}

export const Filter = ({
  defaultPage,
  onApplyFilters,
  onClearFilters,
  filterValues,
}: FilterProps) => {
  const { formatMessage } = useIntl();
  const { preferredLanguage } = usePreferredLanguageV2();
  const [activeTab, setActiveTab] = useState(0);
  const [searchServiceName, setSearchServiceName] = useState('');
  const [searchVendor, setSearchVendor] = useState('');
  const {
    vendorValues,
    setVendorValues,
    statusValues,
    setStatusValues,
    serviceNameValues,
    setServiceNameValues,
    fromCreatedAtValue,
    setFromCreatedAtValue,
    toCreatedAtValue,
    setToCreatedAtValue,
    fromUpdatedAtValue,
    setFromUpdatedAtValue,
    toUpdatedAtValue,
    setToUpdatedAtValue,
    initializeFilters,
    isResetDisabled,
    isApplyDisabled,
  } = useFilter();
  const { data } = useUserMetadata();

  const serviceStorage = JSON.parse(localStorage.getItem('sspa_layout'));
  const mainService = serviceStorage.find((item) => item.name === '@admin-portal/main-mfe');
  const appConfiguration = mainService?.props?.appsConfiguration;

  const handleOpen = (event: React.MouseEvent) => {
    setActiveTab(0);
    initializeFilters(filterValues);
    setSearchServiceName('');
    setSearchVendor('');
  };

  const handleApply = useCallback(() => {
    onApplyFilters({
      status: statusValues,
      serviceName: serviceNameValues,
      vendor: vendorValues,
      fromCreatedAt: fromCreatedAtValue,
      toCreatedAt: toCreatedAtValue,
      fromUpdatedAt: fromUpdatedAtValue,
      toUpdatedAt: toUpdatedAtValue,
    });
  }, [
    statusValues,
    serviceNameValues,
    vendorValues,
    fromCreatedAtValue,
    toCreatedAtValue,
    fromUpdatedAtValue,
    toUpdatedAtValue,
  ]);

  const handleReset = useCallback(() => {
    onClearFilters();
  }, []);

  useEffect(() => {
    handleReset();
  }, [defaultPage]);

  const statusOptions = [
    {
      value: 'REJECTED',
      label: formatMessage({ id: 'statusRequests.rejected' }),
    },
    {
      value: 'COMPLETED',
      label: formatMessage({ id: 'statusRequests.approved' }),
    },
    {
      value: 'CANCELED',
      label: formatMessage({ id: 'statusRequests.canceled' }),
    },
    // failing request because is not mapped on the service
    // {
    //   value: 'FAILED',
    //   label: formatMessage({ id: 'statusRequests.failed' }),
    // },
    {
      value: 'PROCESSING',
      label: formatMessage({ id: 'statusRequests.processing' }),
    },
  ];

  const tabs = useMemo(() => {
    const vendorsOptions = data?.vendors
      ?.map((vendor) => ({
        value: vendor.id,
        label: vendor.displayName,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
      .filter((vendor) => vendor.label?.toLowerCase().includes(searchVendor.toLowerCase()));

    const serviceNameOptions = appConfiguration
      .map((tool) => ({
        value: tool.id,
        label: tool.appName[preferredLanguage] || tool.appName['en-US'],
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
      .filter((serviceName) =>
        serviceName.label?.toLowerCase().includes(searchServiceName.toLowerCase())
      );

    const filterTabs = [
      {
        id: 'vendors',
        name: formatMessage({ id: 'filter.vendors' }),
        content: <Radio options={vendorsOptions} value={vendorValues} setValue={setVendorValues} />,
      },
      ...(defaultPage === 'Resolved'
        ? [
            {
              id: 'status',
              name: formatMessage({ id: 'filter.status' }),
              content: (
                <Radio
                  options={statusOptions.map((status) => ({
                    value: status.value,
                    label: status.label,
                  }))}
                  value={statusValues}
                  setValue={setStatusValues}
                />
              ),
            },
          ]
        : []),
      {
        id: 'serviceName',
        name: formatMessage({ id: 'filter.tools' }),
        content: (
          <Radio
            options={serviceNameOptions}
            value={serviceNameValues}
            setValue={setServiceNameValues}
          />
        ),
      },
      {
        id: 'createdOn',
        name: formatMessage({ id: 'filter.createdOn' }),
        content: (
          <DateFilter
            startDate={fromCreatedAtValue}
            setStartDate={setFromCreatedAtValue}
            endDate={toCreatedAtValue}
            setEndDate={setToCreatedAtValue}
            key="createdOn"
          />
        ),
      },
      ...(defaultPage === 'Resolved'
        ? [
            {
              id: 'lastEdited',
              name: formatMessage({ id: 'filter.lastEdited' }),
              content: (
                <DateFilter
                  startDate={fromUpdatedAtValue}
                  setStartDate={setFromUpdatedAtValue}
                  endDate={toUpdatedAtValue}
                  setEndDate={setToUpdatedAtValue}
                  key="lastEdited"
                />
              ),
            },
          ]
        : []),
    ];
    return filterTabs;
  }, [
    data,
    statusValues,
    vendorValues,
    serviceNameValues,
    fromCreatedAtValue,
    toCreatedAtValue,
    fromUpdatedAtValue,
    toUpdatedAtValue,
    defaultPage,
    searchServiceName,
    searchVendor,
  ]);

  const currentTab = tabs[activeTab].id;
  const hasServiceNameSelected = serviceNameValues?.value.length > 0;
  const hasVendorSelected = vendorValues?.value.length > 0;

  return (
    <Dialog.Root
      variant="noOverlay"
      closeButton={false}
      contentCss={contentCss}
      trigger={
        <ButtonFilter
          icon={() => <FilterIcon size="medium" />}
          size="medium"
          leading
          variant="secondary"
          data-testid="button-filter"
          onClick={handleOpen}
        >
          {formatMessage({ id: 'pendingRequestsPage.commom.filter' })}
          <ChevronDown size="medium" />
        </ButtonFilter>
      }
      actions={
        <ActionsContainer>
          <Dialog.Close>
            <TextButton
              iconPosition="leading"
              icon={X}
              onClick={handleReset}
              disabled={isResetDisabled}
            >
              {formatMessage({ id: 'filter.clearFilters' })}
            </TextButton>
          </Dialog.Close>
          <div style={{ display: 'flex' }}>
            <Dialog.Close>
              <Button variant="secondary">{formatMessage({ id: 'filter.cancel' })}</Button>
            </Dialog.Close>
            <Dialog.Close>
              <Button
                onClick={handleApply}
                css={{ marginLeft: '$4' }}
                disabled={isApplyDisabled(filterValues)}
              >
                {formatMessage({ id: 'filter.applyFilters' })}
              </Button>
            </Dialog.Close>
          </div>
        </ActionsContainer>
      }
    >
      <ContentContainer>
        <TabSelection>
          {tabs.map((tab, index) => {
            return FilterTab({ tab, index, activeTab, setStateFunction: setActiveTab });
          })}
        </TabSelection>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {['serviceName', 'vendors'].includes(currentTab) && (
            <>
              <SearchFieldContainer>
                {currentTab === 'serviceName' && (
                  <>
                    <SearchField.Root
                      placeholder={formatMessage({ id: 'filter.search' })}
                      onSearch={setSearchServiceName}
                      onClear={() => setSearchServiceName('')}
                    />
                    {hasServiceNameSelected && (
                      <ChipsContainer>
                        <Chip.Root>
                          <Chip.Label>{serviceNameValues.label}</Chip.Label>
                          <Chip.DeleteIcon
                            onDelete={() =>
                              setServiceNameValues(defaultInitialFilterValues.serviceName)
                            }
                          />
                        </Chip.Root>
                      </ChipsContainer>
                    )}
                  </>
                )}
                {currentTab === 'vendors' && (
                  <>
                    <SearchField.Root
                      placeholder={formatMessage({ id: 'filter.search' })}
                      onSearch={setSearchVendor}
                      onClear={() => setSearchVendor('')}
                    />
                    {hasVendorSelected && (
                      <ChipsContainer>
                        <Chip.Root>
                          <Chip.Label>{vendorValues.label}</Chip.Label>
                          <Chip.DeleteIcon
                            onDelete={() => setVendorValues(defaultInitialFilterValues.vendor)}
                          />
                        </Chip.Root>
                      </ChipsContainer>
                    )}
                  </>
                )}
              </SearchFieldContainer>
              <Divider />
            </>
          )}
          <TabContent>{tabs[activeTab].content}</TabContent>
        </div>
      </ContentContainer>
    </Dialog.Root>
  );
};

export default Filter;
