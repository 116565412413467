import MessageMap from '../i18n';

const es419: MessageMap = {
  test: {
    translate: 'spanish',
    missing: 'Idioma español seleccionado',
  },
  sidebar: {
    requests: 'Solicitudes',
  },
  breadcrumbs: {
    requests: 'Solicitudes',
    requestDetails: 'Detalles de la solicitud',
  },
  pendingRequestsPage: {
    pendingRequest: 'Solicitudes pendientes',
    results: 'Mostrando {resultsNumber} resultados',
    title: 'Título',
    createdOn: 'Creado el',
    vendor: 'Proveedor',
    country: 'País',
    tool: 'Herramienta',
    id: 'ID',
    requester: 'Solicitante',
    pagination: { pageSizeOptions: 'Filas por página: {options}' },
    emptyMessage: 'Una vez que haya solicitudes pendientes, se enumerarán aquí.',
    commom: {
      filter: 'Filtro',
      search: 'Buscar por título, ID o Solicitante',
    },
  },
  resolvedRequestsPage: {
    resolvedRequest: 'Solicitudes resueltas',
    results: 'Mostrando {resultsNumber} resultados',
    title: 'Título',
    status: 'Estado',
    createdOn: 'Creado el',
    lastEdited: 'Última edición',
    vendor: 'Proveedor',
    country: 'País',
    tool: 'Herramienta',
    id: 'ID',
    requester: 'Solicitante',
    approver: 'Aprobador',
    pagination: { pageSizeOptions: 'Filas por página: {options}' },
    commom: {
      filter: 'Filtro',
      search: 'Buscar por título, identificación o solicitante',
    },
    emptyMessage: 'Una vez que se resuelvan las solicitudes, se enumerarán aquí.',
  },
  statusRequests: {
    approved: 'Aprobado',
    failed: 'Falló',
    processing: 'Procesamiento',
    rejected: 'Rechazado',
    pending: 'Pendiente',
    canceled: 'Cancelado',
    pendingInfo: 'Esta solicitud está pendiente de revisión.',
    failedInfo: 'La solicitud fue aprobada, pero debido a un error del sistema, no se completó.',
    processingInfo: 'La aprobación de esta solicitud se está procesando.',
    rejectedInfo: 'Esta solicitud ha sido rechazada.',
    approvedInfo: 'Esta solicitud ha sido aprobada.',
    canceledInfo: 'Esta solicitud ha sido cancelada.',
  },
  tabs: {
    pending: 'Pendiente',
    resolved: 'Resuelto',
  },
  detailsContainer: {
    requestDetails: 'Detalles de la solicitud',
    vendor: 'Proveedor',
    country: 'País',
    requesters: 'Solicitante',
    sharedWith: 'Compartido con',
    showMore: 'Mostrar más',
    tool: 'Herramienta',
    createdOn: 'Creado el',
    approver: 'Aprobador',
    lastEdited: 'Última edición',
    id: 'ID',
    requestLink: 'Abrir solicitud de URL',
  },
  approvalRequest: {
    error: 'Error',
    errorMessage: 'Algo salió mal. Por favor, inténtelo de nuevo.',
    rejectedSuccessfully: 'La solicitud se rechazó correctamente.',
    approveSuccessfully: 'Se envió tu aprobación y se está procesando.',
    modal: {
      approveButton: 'Aprobar',
      rejectButton: 'Rechazar',
      cancelButton: 'No, volver',
      approveAccept: 'Sí, aprobar',
      approveDescription: '¿Aprobar solicitud?',
      approveTitle: '¿Aprobar solicitud?',
      rejectTitle: '¿Rechazar solicitud?',
      acceptWarn: 'Una vez que lo apruebes, no puedes deshacer esta acción.',
      rejectAccept: 'Sí, rechazar',
      rejectReason: 'Agrega un motivo de rechazo. No podrás deshacer esta acción.',
      reasonTextArea: 'Motivo:',
      rejectionError: 'Debes ingresar el motivo del rechazo.',
    },
    table: {
      information: 'Información',
      currentValue: 'Valor actual',
      newValue: 'Nuevo valor',
      deletedValue: 'Valor eliminado',
      noValue: 'Sin valor',
      removed: 'Valor eliminado',
      removedTooltip: 'Esta información fue eliminada por el solicitante.',
      noValueTooltip: 'Este campo estaba vacío antes de esta solicitud.',
      noValueProvidedTooltip: 'Esta información fue eliminada por el solicitante.',
    },
    informationContainer: {
      requestType: 'Tipo de solicitud',
      infoToReview: 'Información para revisar',
      reviewedInfo: 'Información revisada',
      rejectReason: 'Motivo del rechazo',
      processingAlert: 'La aprobación de esta solicitud se está procesando.',
      failedAlert: 'La solicitud fue aprobada, pero debido a un error del sistema, no se completó.',
      requestTypeName: {
        update: 'Actualizar',
        insert: 'Crear',
        delete: 'Eliminar',
      },
    },
  },
  filter: {
    status: 'Estado',
    vendor: 'Proveedor',
    vendors: 'Proveedores',
    tool: 'Herramienta',
    tools: 'Herramientas',
    createdOn: 'Creado el',
    lastEdited: 'Última edición',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de finalización',
    endDateHint: 'Deja este campo vacío para buscar solo por la fecha de inicio.',
    endDateError: 'La fecha finalización debe ser posterior a la fecha de inicio.',
    invalidDateError: 'Fecha invalida.',
    clearFilters: 'Borrar filtros',
    applyFilters: 'Aplicar',
    cancel: 'Cancelar',
    search: 'Buscar',
  },
  table: {
    loading: 'Cargando elementos...',
  },
  pagination: {
    of: 'de',
  },
};

export default es419;
