import { BaseApproval } from '@/models/Approval';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export interface Filters {
  vendor: { label: string; value: string };
  status: { label: string; value: BaseApproval['status'] | null };
  serviceName: { label: string; value: string };
  sortDirection?: 'asc' | 'desc';
  sortBy?: 'createdAt' | 'updatedAt';
  fromCreatedAt: string;
  toCreatedAt: string;
  fromUpdatedAt: string;
  toUpdatedAt: string;
}

export const isFiltered = (filters: Filters) => {
  const createdAtFilter = !filters.fromCreatedAt && !filters.toCreatedAt;
  const updatedAtFilter = !filters.fromUpdatedAt && !filters.toUpdatedAt;

  return !(
    !filters.vendor.value &&
    !filters.status.value &&
    !filters.serviceName.value &&
    createdAtFilter &&
    updatedAtFilter
  );
};

export interface FilterProps {
  vendorValues: Filters['vendor'];
  setVendorValues: React.Dispatch<React.SetStateAction<Filters['vendor']>>;
  statusValues: Filters['status'];
  setStatusValues: React.Dispatch<React.SetStateAction<Filters['status']>>;
  serviceNameValues: Filters['serviceName'];
  setServiceNameValues: React.Dispatch<React.SetStateAction<Filters['serviceName']>>;
  fromCreatedAtValue: string;
  setFromCreatedAtValue: React.Dispatch<React.SetStateAction<string>>;
  toCreatedAtValue: string;
  setToCreatedAtValue: React.Dispatch<React.SetStateAction<string>>;
  fromUpdatedAtValue: string;
  setFromUpdatedAtValue: React.Dispatch<React.SetStateAction<string>>;
  toUpdatedAtValue: string;
  setToUpdatedAtValue: React.Dispatch<React.SetStateAction<string>>;
  isResetDisabled: boolean;
  isApplyDisabled: (filterValues: Filters) => boolean;
  initializeFilters: (filterValues: Filters) => void;
}
interface ProviderProps {
  children: ReactNode;
  initialFilterValues?: Filters;
}

export const FilterContext = createContext({} as FilterProps);

export const defaultInitialFilterValues: Filters = {
  vendor: { label: '', value: '' },
  status: { label: '', value: null },
  serviceName: { label: '', value: '' },
  sortDirection: 'desc',
  fromCreatedAt: '',
  toCreatedAt: '',
  fromUpdatedAt: '',
  toUpdatedAt: '',
};

const FilterContextProvider = ({
  children,
  initialFilterValues = defaultInitialFilterValues,
}: ProviderProps): JSX.Element => {
  const [vendorValues, setVendorValues] = useState<Filters['vendor']>(
    initialFilterValues['vendor']
  );
  const [statusValues, setStatusValues] = useState<Filters['status']>(
    initialFilterValues['status']
  );
  const [serviceNameValues, setServiceNameValues] = useState<Filters['serviceName']>(
    initialFilterValues['serviceName']
  );
  const [fromCreatedAtValue, setFromCreatedAtValue] = useState<Filters['fromCreatedAt']>(
    initialFilterValues['fromCreatedAt']
  );
  const [toCreatedAtValue, setToCreatedAtValue] = useState<Filters['toCreatedAt']>(
    initialFilterValues['toCreatedAt']
  );
  const [fromUpdatedAtValue, setFromUpdatedAtValue] = useState<Filters['fromUpdatedAt']>(
    initialFilterValues['fromUpdatedAt']
  );
  const [toUpdatedAtValue, setToUpdatedAtValue] = useState<Filters['toUpdatedAt']>(
    initialFilterValues['toUpdatedAt']
  );

  const isResetDisabled = !isFiltered({
    vendor: vendorValues,
    status: statusValues,
    serviceName: serviceNameValues,
    fromCreatedAt: fromCreatedAtValue,
    toCreatedAt: toCreatedAtValue,
    fromUpdatedAt: fromUpdatedAtValue,
    toUpdatedAt: toUpdatedAtValue,
  });

  const isApplyDisabled = (filterValues: Filters) =>
    filterValues.vendor.value === vendorValues.value &&
    filterValues.status.value === statusValues.value &&
    filterValues.serviceName.value === serviceNameValues.value &&
    filterValues.fromCreatedAt === fromCreatedAtValue &&
    filterValues.toCreatedAt === toCreatedAtValue &&
    filterValues.fromUpdatedAt === fromUpdatedAtValue &&
    filterValues.toUpdatedAt === toUpdatedAtValue;

  const initializeFilters = (filterValues: Filters) => {
    setVendorValues(filterValues.vendor);
    setStatusValues(filterValues.status);
    setServiceNameValues(filterValues.serviceName);
    setFromCreatedAtValue(filterValues.fromCreatedAt);
    setToCreatedAtValue(filterValues.toCreatedAt);
    setFromUpdatedAtValue(filterValues.fromUpdatedAt);
    setToUpdatedAtValue(filterValues.toUpdatedAt);
  };

  const value = useMemo(
    () => ({
      vendorValues,
      setVendorValues,
      statusValues,
      setStatusValues,
      serviceNameValues,
      setServiceNameValues,
      fromCreatedAtValue,
      setFromCreatedAtValue,
      toCreatedAtValue,
      setToCreatedAtValue,
      fromUpdatedAtValue,
      setFromUpdatedAtValue,
      toUpdatedAtValue,
      setToUpdatedAtValue,
      isResetDisabled,
      isApplyDisabled,
      initializeFilters,
    }),
    [
      vendorValues,
      setVendorValues,
      statusValues,
      setStatusValues,
      serviceNameValues,
      setServiceNameValues,
      fromCreatedAtValue,
      setFromCreatedAtValue,
      toCreatedAtValue,
      setToCreatedAtValue,
      fromUpdatedAtValue,
      setFromUpdatedAtValue,
      toUpdatedAtValue,
      setToUpdatedAtValue,
      isResetDisabled,
      isApplyDisabled,
      initializeFilters,
    ]
  );

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export const useFilter = () => useContext(FilterContext);

export default FilterContextProvider;
